var wipwrp = window.wipwrp || {};

wipwrp.login = (function($,mzr,doc,win) {

    var self = {};

    self.init = function () {
        _setParsley();
        // _recaptcha();
        _bindEvents();
    }

    function _recaptcha() {
        $('.recaptcha').on('submit', function(e){
            if (!wipwrp.utils.recaptchaCalculated) {
                e.preventDefault();
                wipwrp.utils.addRecaptcha($(this));
            }
        });
    }

    function _setParsley(){
        var recovery$ = $('[data-recovery]');
        var email$ = recovery$.find('[name="reset_email"]');
        var code$ = recovery$.find('[name="reset_idespositore"]');
        var piva$ = recovery$.find('[name="reset_idfiscale"]');

        email$.parsley();
        code$.parsley();
        piva$.parsley();

        $('#form_cambiapassword').parsley();

        $('#verify_recovery_form').click(function (e) {
            var emailValid = email$.parsley().isValid();
            var codeExValid = code$.parsley().isValid();
            var ivaValid = piva$.parsley().isValid();
            console.log('email:'+emailValid)
            console.log('code:'+codeExValid)
            console.log('iva:'+ivaValid)

            if(emailValid || codeExValid || ivaValid){
                $('.js-alert-empty-form').hide();
                $('#form_recovery_pwd input').removeAttr('required');
                return true;
            }else {
                $('#form_recovery_pwd').parsley().validate();
                $('.js-alert-empty-form').show();
                return false;
            }
        });


        $('#cambia_password_submit').click(function(e){
            if ($('#form_cambiapassword').parsley().validate() === true){
                return true;
            }else{
                return false;
            }
        });
    }

    function _bindEvents() {
        var recovery$ = $('[data-recovery]');
        var codecta$ = recovery$.find('[data-cta="code"]');
        var pivacta$ = recovery$.find('[data-cta="piva"]');
        var submits$ = recovery$.find('[data-cta-submit]');
        var recaptchaSubmit$ = recovery$.find('[data-recaptcha-submit]');

        codecta$.on('click', function() {
            recovery$.removeClass('-piva');
            recovery$.addClass('-code');
        });
        pivacta$.on('click', function() {
            recovery$.removeClass('-code');
            recovery$.addClass('-piva');
        });
        submits$.on('click', function (e) {
            e.preventDefault();
            recaptchaSubmit$.click();
        });
    }

    return self;
})(jQuery,Modernizr, document, window);